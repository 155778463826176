<template>
  <svg viewBox="0 0 40 40">
    <path
      d="M23.3327 3.33398H9.99935C9.11529 3.33398 8.26745 3.68517 7.64233 4.31029C7.0172 4.93542 6.66602 5.78326 6.66602 6.66732V33.334C6.66602 34.218 7.0172 35.0659 7.64233 35.691C8.26745 36.3161 9.11529 36.6673 9.99935 36.6673H29.9993C30.8834 36.6673 31.7312 36.3161 32.3564 35.691C32.9815 35.0659 33.3327 34.218 33.3327 33.334V13.334L23.3327 3.33398ZM16.6627 24.614H14.8243V30.0707H13.296V24.614H11.4877V23.334H16.6627V24.614ZM21.2043 30.0707L20.596 28.8523C20.346 28.3823 20.186 28.0323 19.9977 27.6423H19.976C19.8377 28.0307 19.6677 28.3807 19.456 28.8523L18.8977 30.0707H17.156L19.1077 26.6623L17.226 23.334H18.976L19.566 24.564C19.7677 24.9723 19.916 25.3023 20.076 25.6823H20.0977C20.2577 25.2523 20.3877 24.9523 20.5577 24.564L21.126 23.334H22.8643L20.966 26.6223L22.9627 30.0707H21.2043ZM28.511 24.614H26.671V30.0707H25.1427V24.614H23.3343V23.334H28.5093V24.614H28.511ZM23.3327 15.0007H21.666V6.66732L29.9993 15.0007H23.3327Z"
    />
  </svg>
</template>

<script>
export default {
  name: "FileTxtIcon",
};
</script>
